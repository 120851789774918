import { connect } from 'react-redux';
import { List } from 'immutable';
import { denormalize } from 'normalizr';

import { schema } from '../../../../../state/schema';
import {
  loadResource,
  updateResource,
  createResource,
  deleteResource,
  resourceFormDestroy,
  getGeocode,
} from '../../../../../state/modules/resources/actions';
import { sendMessage } from '../../../../../state/actions';
import ResourceForm from '../components/ResourceForm';

const inputs = List([
  'care_guide',
  'care_guides',
  'name',
  'description',
  'telephone',
  'website',
  'email',
  'country',
  'region',
  'city',
  'postal_code',
  'address1',
  'address2',
  'is_universal',
  'countries',
  'regions',
  'subregions',
  'cities',
  'postal_codes',
  'geo',
  'latitude',
  'longitude',
  'tags',
  'service_tags',
  'status',
  'is_pinned',
  'is_pinned_dashboard',
  'partnership',
  'profile',
  'logo',
  'resources_care_partner_tags',
]);

const mapStateToProps = (state, ownProps) => {
  const resource = denormalize(
    state.entities.getIn(['resources', ownProps.id]),
    schema.resource,
    state.entities.toJS()
  );

  const data = {},
    errors = {};
  for (const val of inputs.values()) {
    data[val] = state.entities.getIn(['resources', ownProps.id, val], '');

    if (['profile'].includes(val)) {
      errors[val] = state.calendarEvents.getIn([
        'form',
        'errors',
        'user_profile_id',
      ]);
    } else if (state.resources.getIn(['form', 'errors', val])) {
      errors[val] = state.resources.getIn(['form', 'errors', val]);
    }
  }

  Object.keys(data).forEach((x) => {
    // change select number values to strings to work with ant design select inputs
    if (['status'].includes(x)) {
      data[x] = data[x].toString();
    }

    // get lat/lng from geo point
    if (['geo'].includes(x) && data[x]) {
      data['latitude'] = data[x]['latitude'];
      data['longitude'] = data[x]['longitude'];
    }
  });

  data['country'] =
    resource && resource.country ? resource.country.id.toString() : null;
  data['region'] =
    resource && resource.region ? resource.region.id.toString() : null;
  data['city'] = resource && resource.city ? resource.city.id.toString() : null;
  data['postal_code'] =
    resource && resource.postal_code
      ? resource.postal_code.id.toString()
      : null;

  data['countries'] =
    resource && resource.countries
      ? resource.countries.map((x) => x.id.toString())
      : [];
  data['regions'] =
    resource && resource.regions
      ? resource.regions.map((x) => x.id.toString())
      : [];
  data['subregions'] =
    resource && resource.subregions
      ? resource.subregions.map((x) => x.id.toString())
      : [];
  data['cities'] =
    resource && resource.cities
      ? resource.cities.map((x) => x.id.toString())
      : [];
  data['postal_codes'] =
    resource && resource.postal_codes
      ? resource.postal_codes.map((x) => x.id.toString())
      : [];

  const care_guide = data['care_guide'] ?? null;

  if (Array.isArray(data['care_guides'])) {
    if (data['care_guides'].length < 1) {
      data['care_guides'] = care_guide ? [care_guide] : [];
    }
  } else {
    data['care_guides'] = care_guide ? [care_guide] : [];
  }

  if (data['tags'] === '') {
    data['tags'] = [];
  }

  if (data['service_tags'] === '') {
    data['service_tags'] = [];
  }
  if (data['resources_care_partner_tags'] === '') {
    data['resources_care_partner_tags'] = [];
  }

  return {
    isLoading: state.resources.get('isLoading'),
    isGeocodeLoading: state.resources.get('isGeocodeLoading'),
    isSubmitting: state.resources.getIn(['form', 'isSubmitting']),
    success: state.resources.getIn(['form', 'success']),
    created_id: state.resources.getIn(['form', 'created_id']),
    data: data,
    errors: errors,
    createdAt: state.entities.getIn(
      ['resources', ownProps.id, 'created_at'],
      null
    ),
    updatedAt: state.entities.getIn(
      ['resources', ownProps.id, 'updated_at'],
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    load: (id, cb) => {
      dispatch(loadResource(id, cb));
    },
    update: (id, data, cb) => {
      dispatch(updateResource(id, data, cb));
    },
    create: (data, cb) => {
      dispatch(createResource(data, cb));
    },
    delete: (id, cb) => {
      dispatch(deleteResource(id, cb));
    },
    destroyForm: (formState) => {
      dispatch(resourceFormDestroy(formState));
    },
    sendMessage: (level, title, body, expires) => {
      dispatch(sendMessage(level, title, body, expires));
    },
    getGeocode: (location, cb) => {
      dispatch(getGeocode(location, cb));
    },
  };
};

const ResourceFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResourceForm);

export default ResourceFormContainer;
